import './App.css';
import './Alagard.css';
import background from './images/background.png';
import ground from './images/ground.png';
import { Moonriver, useEthers, useSendTransaction } from '@usedapp/core';
import { useEffect, useState } from 'react';


function App() {
  const { activateBrowserWallet, switchNetwork, account, chainId } = useEthers();
  const { sendTransaction } = useSendTransaction();
  const [mint, setMint] = useState(false);

  async function switchAndSend() {
    activateBrowserWallet();
    await switchNetwork(Moonriver.chainId);
    setMint(true);
  }

  /* eslint react-hooks/exhaustive-deps: "off" */
  useEffect(() => {
    console.log('new data:', account, mint)
    if (account != null && mint && chainId === Moonriver.chainId) {
      sendTransaction({
        to: '0xaB4089F47109B8260001A3448FdD10343C014Ac6',
        value: '10000000000000000',
        chainId: Moonriver.chainId,
        data: '0x0180e2f8'
      });
    }
  }, [account, mint, chainId]);

  return (
    <div className="app">
      <div className='background' style={{ backgroundImage: `url(${background})` }}>
        <img
          src='knight.gif' alt='Cursed knight'
          style={{
            height: 64, width: 91, position: 'relative',
            top: 'calc(100% - 64px)',
            left: 'calc(50%)'
          }}
        />
        <img
          src='fire.gif' alt='Campfire'
          style={{
            height: 64, width: 64, position: 'relative',
            top: 'calc(100% - 54px)',
            left: 'calc(50% - 130px)'
          }}
        />
      </div>
      <div className='ground' style={{ backgroundImage: `url(${ground})` }} />
      <div style={{ height: 'calc(100vh - 528px)', width: '100%' }}>
        <h3 className='title'>Whipsers in the Winterwoods</h3>
        <p>A cursed knight must wander the Winterwoods, capturing wisps to satiate the needs of the whispering children.</p>
        <p>Alpha Q1 2024 on Moonriver.</p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src='sovereignless-soul.png' alt='Sovereignless Soul' width={64} height={64}
            onClick={switchAndSend} className='glow'
          />
        </div>
      </div>
    </div>
  );
}

export default App;
