import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { DAppProvider, Moonriver } from '@usedapp/core';

const config = {
  readOnlyChainId: Moonriver.chainId,
  readOnlyUrls: {
    [Moonriver.chainId]: 'https://rpc.api.moonriver.moonbeam.network'
  },
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <App />
    </DAppProvider>
  </React.StrictMode>
);

